<template>
  <!--begin::Stats Widget 11-->
  <div class="card card-custom gutter-b">
    <!--begin::Body-->
  


    <div class="card-body p-0">
    <div
      class="
        d-flex
        align-items-center
        justify-content-between
        card-spacer
        flex-grow-1
      "
    >
      <v-avatar
        size="50"
        class="mr-2 "
        style="background-color: #DEDEDE;"
      >
        <i class="mdi mdi-credit-card mdi-24px text-primary"></i>
      </v-avatar>
      <div class="d-flex flex-column text-right">
        <span class="text-dark-75  text-primary font-weight-bolder font-size-h3">
          {{$store.state.auth.currency}}{{ parseFloat(netTotal).toFixed(2) }}
        </span>
        <span class="text-dark font-weight-bolder mt-2 text-sm" style="font-size: 10px;;">Net Total</span>
      </div>
    </div>
  </div>
    <!--end::Body-->
  </div>
  <!--end::Stats Widget 11-->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TotalOrdersWidget",
  props: ["orders"],
  data() {
    return {
      //
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    netTotal() {
      let count = 0;
      for (let index = 0; index < this.orders.length; index++) {
        const status = this.orders[index].status;
        if (status !== "Cancelled") {
          count += this.orders[index].total;
        }
      }
      return count;
    },
  },
  mounted() {
    //
  },
};
</script>
